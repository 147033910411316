import { Feature, Polygon, Properties } from '@turf/helpers'
import { ImageSourceOptions } from 'mapbox-gl'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Layer, MapRef, Source } from 'react-map-gl'
import { useTheme } from 'styled-components'

import { config } from 'config'

interface Props {
  lotImageUrl?: string
  lotId: string
  area: Feature<Polygon, Properties>
  mapRef: MapRef
  isCloudy?: boolean
}

const dashArraySequence = [
  [0, 4, 3],
  [0.5, 4, 2.5],
  [1, 4, 2],
  [1.5, 4, 1.5],
  [2, 4, 1],
  [2.5, 4, 0.5],
  [3, 4, 0],
  [0, 0.5, 3, 3.5],
  [0, 1, 3, 3],
  [0, 1.5, 3, 2.5],
  [0, 2, 3, 2],
  [0, 2.5, 3, 1.5],
  [0, 3, 3, 1],
  [0, 3.5, 3, 0.5],
]

// This number controls the animation speed.
const STEP_MAX_TIMES = 3

export const AreaSource: React.FC<Props> = ({ lotImageUrl, lotId, area, mapRef, isCloudy }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { colors } = useTheme()

  const frame = useRef(0)
  const step = useRef(0)
  const numberOfTimesStepWasRepeated = useRef(0)
  const loading = useRef(false)

  const animateDashArray = useCallback(() => {
    const map = mapRef.getMap()
    const layer = map.getLayer(`lot-line-dash-${lotId}`)

    if (!loading.current || !mapRef || !layer) return

    const isTheLastStepRepetition = numberOfTimesStepWasRepeated.current === STEP_MAX_TIMES

    numberOfTimesStepWasRepeated.current = !isTheLastStepRepetition
      ? numberOfTimesStepWasRepeated.current + 1
      : 0

    if (isTheLastStepRepetition) {
      map.setPaintProperty(
        `lot-line-dash-${lotId}`,
        'line-dasharray',
        dashArraySequence[step.current],
      )
      step.current = step.current + 1 < dashArraySequence.length ? step.current + 1 : 0
    }

    requestAnimationFrame(animateDashArray)
  }, [lotId, mapRef])

  const onHandleImageChange = useCallback(
    (sourcedata: mapboxgl.MapSourceDataEvent & mapboxgl.EventData) => {
      if (sourcedata.source.type === 'image') {
        const source = sourcedata.source as ImageSourceOptions
        if (source.url === lotImageUrl) {
          setIsLoading(false)
          loading.current = false
          step.current = 0
          numberOfTimesStepWasRepeated.current = 0
          cancelAnimationFrame(frame.current)
        }
      }
    },
    [lotImageUrl],
  )

  useEffect(() => {
    if (!mapRef || !config.isSentinelImageryOn) return undefined
    if (lotImageUrl) {
      setIsLoading(true)
      loading.current = true
      frame.current = requestAnimationFrame(animateDashArray)
    }
    mapRef.on('sourcedata', onHandleImageChange)

    return () => {
      mapRef.off('sourcedata', onHandleImageChange)
    }
  }, [animateDashArray, lotImageUrl, mapRef, onHandleImageChange])

  const backgroundColor = useMemo(() => {
    if (isCloudy) return colors.white

    return isLoading || !lotImageUrl ? colors.new.baseColor[400] : 'transparent'
  }, [colors.new.baseColor, colors.white, isCloudy, isLoading, lotImageUrl])

  return (
    <>
      <Source type="geojson" id={lotId} data={area}>
        <Layer
          beforeId="country-label"
          id={lotId}
          type="fill"
          paint={{
            'fill-color': backgroundColor,
            'fill-opacity': isLoading ? 0.6 : 1,
          }}
        />
        <Layer
          beforeId="country-label"
          id={`lot-line-${lotId}`}
          type="line"
          paint={{
            'line-color': colors.new.baseColor[900],
            'line-width': 4,
            'line-opacity': 1,
          }}
        />

        <Layer
          beforeId="country-label"
          id={`lot-line-dash-${lotId}`}
          type="line"
          paint={{
            'line-color': isLoading ? colors.new.baseColor[500] : 'transparent',
            'line-width': 4,
            'line-opacity': 0.6,
            'line-dasharray': [],
          }}
        />
      </Source>
    </>
  )
}
