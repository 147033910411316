import ReactMapGL, {
  Source,
  Layer,
  ViewStateChangeEvent,
  useMap,
  MapLayerMouseEvent,
} from 'react-map-gl'
import bbox from '@turf/bbox'
import { Feature, multiLineString, Polygon, polygon, Properties } from '@turf/helpers'
import React, { useCallback } from 'react'
import { useTheme } from 'styled-components'
import { isNil } from 'lodash'

import { MAP } from 'consts'
import { config } from 'config'
import { Lot, MapIndex, SatelliteProviders, WeatherType } from 'types'
import { GeoUtils } from 'utils'
import { AreaTooltip } from 'features/MainRouter/components'
import { Dam } from 'hooks/useQueryDams/api'
import { useImageUrl } from 'hooks'

import { InformationPopup, Control } from './components'
import { DrawArea, DrawingOption, Features, PopupInfo, ToolMode } from '../../types'
import { DrawMode } from '../../hooks/useDrawingTool'
import { AreaSource } from '../AreaSource'
import { UnavailableIcon } from '../UnavailableIcon'
import { useLotMapDateInformation } from '../../hooks'

interface Props {
  selectedLots: Lot[]
  viewport: {
    latitude: number
    longitude: number
    zoom: number
  }
  onHandleMove: (evt: ViewStateChangeEvent) => void
  onHandleClick: (evt: MapLayerMouseEvent) => void
  onHandleMouseEnter: (evt: MapLayerMouseEvent) => void
  onHandleMouseLeave: (evt: MapLayerMouseEvent) => void
  popupInfo?: PopupInfo
  interactiveMapLayersIds: string[]
  isCompareModeSelected: boolean
  drawMode: DrawMode
  cursor: string
  onHandleCreateFeature: ({ features }: { features: Features }) => void
  onHandleUpdateFeature: ({ features }: { features: Features }) => void
  onHandleDeleteFeature: (featureId: string) => void
  drawnAreasFeatures: Feature<Polygon, Properties>[]
  drawingOption: DrawingOption
  selectedCalendarDate?: string
  rtkLots?: { id: number; rtk?: number[][][] }[]
  selectedMapIndex: MapIndex
  drawnAreas: DrawArea[]
  toolMode: ToolMode
  showRtk: boolean
  showPopup: boolean
  popupCoordinates?: number[]
  dams: Dam[]
  isShowDamsModeSelected: boolean
  selectedSatellite: SatelliteProviders
}

export const DefaultMap: React.FC<Props> = ({
  onHandleMove,
  popupInfo,
  interactiveMapLayersIds,
  isCompareModeSelected,
  viewport,
  onHandleClick,
  onHandleMouseLeave,
  onHandleMouseEnter,
  drawMode,
  cursor,
  onHandleCreateFeature,
  onHandleUpdateFeature,
  onHandleDeleteFeature,
  drawnAreasFeatures,
  drawingOption,
  selectedCalendarDate,
  selectedLots,
  rtkLots,
  selectedMapIndex,
  drawnAreas,
  toolMode,
  showRtk,
  showPopup,
  popupCoordinates,
  dams,
  isShowDamsModeSelected,
  selectedSatellite,
}) => {
  const { colors } = useTheme()
  const { map } = useMap()
  const { getLotMapDateInformation, getHarvestDateIsValid } = useLotMapDateInformation()
  const { getUrlWithToken } = useImageUrl()
  const showIcon = viewport.zoom > MAP.ZOOM.ICONS.ENABLED
  const iconSize = viewport.zoom > MAP.ZOOM.ICONS.CLOSE ? 'medium' : 'small'

  const getLotMapDateData = useCallback(
    (lot: Lot, date: string) => {
      const lotDateData = getLotMapDateInformation({
        lot,
        selectedMapIndex,
        selectedDate: date,
        selectedSatellite,
      })

      return {
        isCloudy: lotDateData?.isCloudy,
        lotImageUrl: lotDateData?.url,
        unavailableImage: !lotDateData,
        unavailableIndex: !lotDateData?.isIndexAvailable,
      }
    },
    [getLotMapDateInformation, selectedMapIndex, selectedSatellite],
  )

  const getDamMapDateData = useCallback(
    (dam: Dam, selectedDate?: string) => {
      const selectedDamDate = dam.calendar.find(date => date.date === selectedDate)
      const isImageAvailable =
        selectedDamDate?.mapUrls.waterSurface?.isAvailable &&
        selectedDamDate?.mapUrls?.waterSurface?.url

      const imageUrl = isImageAvailable
        ? getUrlWithToken(selectedDamDate!.mapUrls!.waterSurface!.url!)
        : undefined

      const weatherType = selectedDamDate?.mapUrls.waterSurface?.weatherType
      const isCloudy = weatherType === WeatherType.CLOUDY
      const unavailableImage = !isImageAvailable && isNil(weatherType)

      return {
        imageUrl,
        isCloudy,
        unavailableImage,
      }
    },
    [getUrlWithToken],
  )

  return (
    <ReactMapGL
      onMove={onHandleMove}
      interactiveLayerIds={popupInfo ? undefined : interactiveMapLayersIds}
      mapStyle={MAP.STYLES.SATELLITE_STREET}
      {...(!isCompareModeSelected && viewport)}
      id="map"
      onClick={onHandleClick}
      onMouseEnter={onHandleMouseEnter}
      onMouseLeave={onHandleMouseLeave}
      cursor={drawMode === 'draw_polygon' ? 'crosshair' : cursor}
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        zIndex: 1,
      }}
    >
      <Control
        mapOptions={{ displayControlsDefault: false }}
        onCreate={onHandleCreateFeature}
        onUpdate={onHandleUpdateFeature}
        onDeleteFeature={onHandleDeleteFeature}
        features={drawnAreasFeatures}
        mode={drawMode}
        drawingOption={drawingOption}
      />

      {isShowDamsModeSelected
        ? map &&
          dams.map(dam => {
            const { imageUrl, isCloudy, unavailableImage } = getDamMapDateData(
              dam,
              selectedCalendarDate,
            )

            const { latitude, longitude } = GeoUtils.getPolygonCentroid(dam.area.coordinates)

            return (
              <React.Fragment key={dam.id}>
                <AreaSource
                  lotImageUrl={imageUrl}
                  lotId={dam.id.toString()}
                  area={polygon(dam.area.coordinates)}
                  mapRef={map}
                  isCloudy={isCloudy}
                />
                {config.isSentinelImageryOn && imageUrl && (
                  <Source
                    type="image"
                    url={imageUrl}
                    coordinates={GeoUtils.bboxCoords(bbox(dam.area))}
                  >
                    <Layer
                      type="raster"
                      beforeId={dam.id.toString()}
                      id={`image-dam-${dam.id.toString()}`}
                      paint={{}}
                    />
                  </Source>
                )}
                {(isCloudy || unavailableImage) && showIcon && (
                  <UnavailableIcon
                    latitude={latitude}
                    longitude={longitude}
                    unavailableImage={unavailableImage}
                    isCloudy={isCloudy}
                    size={iconSize}
                  />
                )}
              </React.Fragment>
            )
          })
        : selectedCalendarDate &&
          selectedLots.map(lot => {
            const { isCloudy, lotImageUrl, unavailableImage, unavailableIndex } = getLotMapDateData(
              lot,
              selectedCalendarDate,
            )
            const isLotHarvested = getHarvestDateIsValid(
              lot.riceLot.harvestDate ?? undefined,
              selectedCalendarDate,
            )
            const rtk = rtkLots?.find(rtkLot => rtkLot.id === lot.id)?.rtk

            const lotRtk = rtk ? multiLineString(rtk) : undefined

            const { latitude, longitude } = GeoUtils.getPolygonCentroid(lot.area.coordinates)

            const isHarvested =
              (lot.riceLot.isHarvested && isLotHarvested && unavailableImage) ?? false
            const showUnavailableIcon =
              showIcon && (isCloudy || unavailableImage || unavailableIndex || isHarvested)

            return (
              <React.Fragment key={lot.id}>
                {map && (
                  <AreaSource
                    lotImageUrl={lotImageUrl}
                    lotId={lot.id.toString()}
                    area={polygon(lot.area.coordinates)}
                    mapRef={map}
                    isCloudy={isCloudy}
                  />
                )}

                {config.isSentinelImageryOn && lotImageUrl && (
                  <Source
                    type="image"
                    url={lotImageUrl}
                    coordinates={GeoUtils.bboxCoords(bbox(lot.area))}
                  >
                    <Layer
                      type="raster"
                      beforeId={lot.id.toString()}
                      id={`image-${lot.id.toString()}`}
                      paint={{}}
                    />
                  </Source>
                )}
                {!drawnAreas.length && toolMode !== 'draw' && showRtk && lotRtk && (
                  <Source
                    key={`rtk-${lot.id.toString()}`}
                    type="geojson"
                    id={`rtk-${lot.id.toString()}`}
                    data={lotRtk}
                  >
                    <Layer
                      beforeId="country-label"
                      id={`lot-rtk-${lot.id}`}
                      type="line"
                      paint={{
                        'line-color': colors.white,
                        'line-width': 1.5,
                      }}
                    />
                  </Source>
                )}
                {showUnavailableIcon && (
                  <UnavailableIcon
                    latitude={latitude}
                    longitude={longitude}
                    unavailableImage={unavailableImage}
                    isCloudy={isCloudy}
                    unavailableIndex={unavailableIndex}
                    isHarvested={isHarvested}
                    selectedMapIndex={selectedMapIndex}
                    size={iconSize}
                  />
                )}
              </React.Fragment>
            )
          })}
      {showPopup && popupInfo && (
        <InformationPopup
          data={popupInfo}
          isShowDamsModeSelected={isShowDamsModeSelected}
          coordinates={{
            latitude: popupCoordinates?.[1],
            longitude: popupCoordinates?.[0],
          }}
        />
      )}
      {drawingOption !== 'hide' &&
        drawnAreas?.map(drawnArea => <AreaTooltip drawAreaInformation={drawnArea} />)}
    </ReactMapGL>
  )
}
