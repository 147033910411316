import { Typography, Row, Col, Space } from 'antd'
import styled, { useTheme } from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isNumber } from 'lodash'

import { EllipsisText, EllipsisTitle, TagText } from 'components'
import { Indicators } from 'types'
import { DashboardIcon } from 'assets/icons'

import { Indicator } from '../../../../../../../../components'
import { PopupInfo } from '../../../../../../types'

const Container = styled(Row)`
  flex-direction: column;
`

const Header = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 4px 4px 0px 0px;
  background-color: ${({ theme }) => theme.colors.new.baseColor[850]};
`

const Body = styled(Space).attrs({
  direction: 'vertical',
  size: 8,
})`
  padding: 16px;
  justify-content: space-between;
`

const FullWidthRow = styled(Row)`
  border-radius: 4px 4px 0px 0px;
  background-color: ${({ theme }) => theme.colors.new.baseColor[900]};
  width: 100%;
`

const { Text: AntText } = Typography

const Text = styled(AntText)`
  font-size: 11px !important;
  font-family: Inter;
  font-weight: 600;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.white};
`

const DaysText = styled(Text)`
  padding: 4px 20px;
  border-radius: ${({ theme }) => theme.foundation.smallRadius};
  height: 100%;
  background-color: ${({ theme }) => theme.colors.new.baseColor[850]};
`

const IconContainer = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.new.baseColor[850]};
  cursor: pointer;
`
interface Props {
  data: PopupInfo
}

export const LotCard: React.FC<Props> = ({ data }) => {
  const { colors } = useTheme()
  const navigate = useNavigate()
  const { t: commonT } = useTranslation('common')
  const { t } = useTranslation('map')

  const isHarvested = !!data.isHarvested
  const isCloudy = !!data.isCloudy
  const unavailableImage = !!data.unavailableImage
  const unavailableIndex = !!data.unavailableIndex

  const STATUS_MESSAGES = {
    unavailableImage: t('lotPopup.unavailableImage'),
    unavailableIndex: t('lotPopup.unavailableIndex'),
    isCloudy: t('lotPopup.isCloudy'),
    isHarvested: commonT('tags.harvested'),
  }

  type StatusKey = keyof typeof STATUS_MESSAGES

  const conditions: Record<StatusKey, boolean> = {
    isHarvested,
    unavailableImage,
    unavailableIndex,
    isCloudy,
  }

  const tag = (Object.keys(conditions) as StatusKey[]).find(key => conditions[key])

  const getDaysSinceEmergenceDate = (days?: number | null) => (isNumber(days) ? days : '-')
  const getTagMessage = (statusKey: StatusKey): string => STATUS_MESSAGES[statusKey]

  return (
    <Container>
      <Header>
        <EllipsisTitle
          title={data.name}
          overlayInnerStyle={{
            fontWeight: 600,
            fontSize: '13px',
            color: colors.white,
            fontFamily: 'Inter',
            width: '100%',
          }}
        />
      </Header>
      <Body>
        <FullWidthRow style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <DaysText>
            {getDaysSinceEmergenceDate(data.daysSinceEmergencyDate)}{' '}
            {commonT('vocabulary.daysAfterEmergenceAbbreviation')}
          </DaysText>
          <IconContainer onClick={() => navigate(`/dashboard/lots/${data.id}`)}>
            <DashboardIcon color={colors.white} width={17} height={17} />
          </IconContainer>
        </FullWidthRow>
        <FullWidthRow>
          <Col span={10}>
            <Text>{t('lotPopup.size')}</Text>
          </Col>
          <Col span={14}>
            <EllipsisText
              text={commonT('systemsFormat.area', { val: { area: data.size, withDecimals: 1 } })}
              overlayInnerStyle={{
                fontWeight: 600,
                width: '100%',
                fontSize: '11px',
                color: colors.new.baseColor[400],
                fontFamily: 'Inter',
              }}
            />
          </Col>
        </FullWidthRow>
        <FullWidthRow>
          <Col span={10}>
            <Text>{t('lotPopup.crop')}</Text>
          </Col>
          <Col span={14}>
            <EllipsisText
              text={data.crop ?? '-'}
              overlayInnerStyle={{
                fontWeight: 600,
                width: '100%',
                fontSize: '11px',
                color: colors.new.baseColor[400],
                fontFamily: 'Inter',
              }}
            />
          </Col>
        </FullWidthRow>
        <FullWidthRow>
          <Col span={10}>
            <Text>{t('lotPopup.variety')}</Text>
          </Col>
          <Col span={14}>
            <EllipsisText
              text={data.variety ?? '-'}
              overlayInnerStyle={{
                fontWeight: 600,
                width: '100%',
                fontSize: '11px',
                color: colors.new.baseColor[400],
                fontFamily: 'Inter',
              }}
            />
          </Col>
        </FullWidthRow>
        {tag ? (
          <TagText text={getTagMessage(tag)} />
        ) : (
          <Space direction="vertical" size={5}>
            <Text>{t('lotPopup.satelliteIndices')}</Text>
            <Row gutter={8}>
              <Col>
                <Indicator
                  type={Indicators.IRRIGATION}
                  indicatorStatus={data.irrigationIndicator}
                />
              </Col>
              <Col>
                <Indicator
                  type={Indicators.CHLOROPHYLL}
                  indicatorStatus={data.chlorophyllIndicator}
                />
              </Col>
            </Row>
          </Space>
        )}
      </Body>
    </Container>
  )
}
