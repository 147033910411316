import { useCallback } from 'react'
import { isNil } from 'lodash'
import dayjs from 'dayjs'

import { MapIndex, SatelliteProviders, WeatherType } from 'types'
import { useImageUrl } from 'hooks'

import { LotsUtils } from '../utils'
import { Lot } from '../types'

export const useLotMapDateInformation = () => {
  const { getUrlWithToken } = useImageUrl()

  const getLotMapDateInformation = useCallback(
    ({
      lot,
      selectedMapIndex,
      selectedDate,
      selectedSatellite,
    }: {
      lot: Lot
      selectedMapIndex: MapIndex
      selectedDate?: string
      selectedSatellite: SatelliteProviders
    }) => {
      const selectedLotDate = lot.riceLot.calendar.find(date => date.date === selectedDate)

      const lotDateData = LotsUtils.getCalendarDateByProvider(
        selectedSatellite,
        selectedMapIndex,
        selectedLotDate?.mapUrls,
      )

      if (!lotDateData) return undefined

      const baseUrl = lotDateData?.isAvailable ? lotDateData.url : undefined
      const url = baseUrl ? getUrlWithToken(baseUrl) : undefined

      return {
        url,
        isAvailable: lotDateData.isAvailable,
        weatherType: lotDateData.weatherType,
        isIndexAvailable: !isNil(lotDateData.weatherType),
        isCloudy: lotDateData?.weatherType === WeatherType.CLOUDY,
      }
    },
    [getUrlWithToken],
  )

  const getHarvestDateIsValid = useCallback((harvestDate?: string, lotsSelectedDate?: string) => {
    if (!harvestDate || !lotsSelectedDate) return false

    return dayjs(harvestDate).isBefore(lotsSelectedDate)
  }, [])

  return { getLotMapDateInformation, getHarvestDateIsValid }
}
