import ReactMapGL, { Layer, Source, useMap as useReactMapGl } from 'react-map-gl'
import { multiLineString, polygon } from '@turf/helpers'
import React, { useCallback, useEffect } from 'react'
import bbox from '@turf/bbox'
import { useTheme } from 'styled-components'

import { MAP } from 'consts'
import { config } from 'config'
import { MapContext } from 'features/MainRouter/contexts'
import { Lot } from 'types'
import { GeoUtils } from 'utils'

import { AreaSource } from '../AreaSource'
import { UnavailableIcon } from '../UnavailableIcon'
import { useLotMapDateInformation } from '../../hooks'

interface Props {
  lots: Lot[]
  viewport: {
    latitude: number
    longitude: number
    zoom: number
  }
  onMount: () => void
  showRtk: boolean
  rtkLots?: { id: number; rtk?: number[][][] }[]
  onClick: () => void
}

export const CompareImagesMap: React.FC<Props> = ({
  lots,
  viewport,
  onMount,
  showRtk,
  rtkLots,
  onClick,
}) => {
  const { colors } = useTheme()
  const { selectedCompareMapIndex, selectedCompareCalendarDate, selectedCompareSatellite } =
    MapContext.useMapContext()
  const { compareMap } = useReactMapGl()
  const showIcon = viewport.zoom > MAP.ZOOM.ICONS.ENABLED
  const iconSize = viewport.zoom > MAP.ZOOM.ICONS.CLOSE ? 'medium' : 'small'

  const { getLotMapDateInformation, getHarvestDateIsValid } = useLotMapDateInformation()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onMount, [compareMap])

  const getLotMapDateData = useCallback(
    (lot: Lot, date: string) => {
      const lotDateData = getLotMapDateInformation({
        lot,
        selectedMapIndex: selectedCompareMapIndex,
        selectedDate: date,
        selectedSatellite: selectedCompareSatellite,
      })

      return {
        isCloudy: lotDateData?.isCloudy,
        lotImageUrl: lotDateData?.url,
        unavailableImage: !lotDateData,
        unavailableIndex: !lotDateData?.isIndexAvailable,
      }
    },
    [getLotMapDateInformation, selectedCompareMapIndex, selectedCompareSatellite],
  )

  return (
    <ReactMapGL
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
      }}
      mapStyle={MAP.STYLES.SATELLITE_STREET}
      id="compareMap"
      initialViewState={{ ...viewport }}
      onClick={onClick}
    >
      {selectedCompareCalendarDate &&
        lots.map(lot => {
          const { isCloudy, lotImageUrl, unavailableImage, unavailableIndex } = getLotMapDateData(
            lot,
            selectedCompareCalendarDate,
          )
          const isLotHarvested = getHarvestDateIsValid(
            lot.riceLot.harvestDate ?? undefined,
            selectedCompareCalendarDate,
          )
          const { latitude, longitude } = GeoUtils.getPolygonCentroid(lot.area.coordinates)
          const isHarvested =
            (lot.riceLot.isHarvested && isLotHarvested && unavailableImage) ?? false
          const showUnavailableIcon =
            showIcon && (isCloudy || unavailableImage || unavailableIndex || isHarvested)

          const rtk = rtkLots?.find(rtkLot => rtkLot.id === lot.id)?.rtk

          const lotRtk = rtk ? multiLineString(rtk) : undefined

          return (
            <React.Fragment key={lot.id}>
              <>
                {compareMap && (
                  <AreaSource
                    lotImageUrl={lotImageUrl}
                    lotId={lot.id.toString()}
                    area={polygon(lot.area.coordinates)}
                    mapRef={compareMap}
                    isCloudy={isCloudy}
                  />
                )}
                {config.isSentinelImageryOn && lotImageUrl && (
                  <Source
                    type="image"
                    id={`image-${lot.id.toString()}`}
                    url={lotImageUrl}
                    coordinates={GeoUtils.bboxCoords(bbox(lot.area))}
                  >
                    <Layer
                      beforeId={lot.id.toString()}
                      type="raster"
                      id={`image-${lot.id.toString()}`}
                      paint={{}}
                    />
                  </Source>
                )}
                {showRtk && lotRtk && (
                  <Source
                    key={`compare-rtk-${lot.id.toString()}`}
                    type="geojson"
                    id={`rtk-${lot.id.toString()}`}
                    data={lotRtk}
                  >
                    <Layer
                      beforeId={lot.id.toString()}
                      id={`compare-lot-rtk-${lot.id}`}
                      type="line"
                      paint={{
                        'line-color': colors.white,
                        'line-width': 1.5,
                      }}
                    />
                  </Source>
                )}
                {showUnavailableIcon && (
                  <UnavailableIcon
                    latitude={latitude}
                    longitude={longitude}
                    unavailableImage={unavailableImage}
                    isCloudy={isCloudy}
                    unavailableIndex={unavailableIndex}
                    isHarvested={isHarvested}
                    selectedMapIndex={selectedCompareMapIndex}
                    size={iconSize}
                  />
                )}
              </>
            </React.Fragment>
          )
        })}
    </ReactMapGL>
  )
}
