export const MAP = {
  DEFAULT_CENTER: { LATITUDE: -31.3922, LONGITUDE: -58.0169 },
  DEFAULT_TRANSITION: { transitionDuration: 2000 },
  ZOOM: {
    DEFAULT: 8,
    CLOSE: 14,
    ICONS: {
      ENABLED: 12.5,
      CLOSE: 14,
    },
  },
  STYLES: {
    SATELLITE_STREET: 'mapbox://styles/mapbox/satellite-streets-v11?optimize=true',
  },
  COUNTRIES: {
    AR: 'ar',
  },
  LANGUAGES: {
    ES: 'es',
  },
  STATIC_IMAGES: {
    SIZE: '512x400',
    URL: 'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/static/',
  },
}
