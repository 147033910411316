import { Col } from 'antd'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { EllipsisText, EllipsisTitle, TagText } from 'components'
import { useFormatNumbersWithScale } from 'hooks'

import { PopupInfo } from '../../../../../../types'
import { Body, Container, FullWidthRow, Header, Text } from './components'

interface Props {
  data: PopupInfo
}

export const DamCard: React.FC<Props> = ({ data }) => {
  const { colors } = useTheme()
  const { t } = useTranslation('map')
  const { formatNumberWithScale } = useFormatNumbersWithScale()

  const areaInfo = data.area ? formatNumberWithScale(data.area) : undefined
  const volumeInfo = data.volume ? formatNumberWithScale(data.volume) : undefined

  const isCloudy = !!data.isCloudy
  const unavailableImage = !!data.unavailableImage

  const STATUS_MESSAGES = {
    unavailableImage: t('damPopup.unavailableImage'),
    isCloudy: t('damPopup.isCloudy'),
  }

  type StatusKey = keyof typeof STATUS_MESSAGES

  const conditions: Record<StatusKey, boolean> = {
    unavailableImage,
    isCloudy,
  }

  const tag = (Object.keys(conditions) as StatusKey[]).find(key => conditions[key])

  const getTagMessage = (statusKey: StatusKey): string => STATUS_MESSAGES[statusKey]

  return (
    <Container>
      <Header>
        <EllipsisTitle
          title={`${t('damPopup.title')} ${data.name}`}
          overlayInnerStyle={{
            fontWeight: 600,
            fontSize: '13px',
            color: colors.white,
            fontFamily: 'Inter',
            width: '100%',
          }}
        />
      </Header>
      <Body>
        <FullWidthRow>
          <Col span={10}>
            <Text>{t('damPopup.area')}</Text>
          </Col>
          <Col span={14}>
            <EllipsisText
              text={areaInfo ? `${areaInfo.value}  ${areaInfo.scale} m2` : '-'}
              overlayInnerStyle={{
                fontWeight: 600,
                width: '100%',
                fontSize: '11px',
                color: colors.new.baseColor[400],
                fontFamily: 'Inter',
              }}
            />
          </Col>
        </FullWidthRow>

        <FullWidthRow>
          <Col span={10}>
            <Text>{t('damPopup.volume')}</Text>
          </Col>
          <Col span={14}>
            <EllipsisText
              text={volumeInfo ? `${volumeInfo.value}  ${volumeInfo.scale} m3` : '-'}
              overlayInnerStyle={{
                fontWeight: 600,
                width: '100%',
                fontSize: '11px',
                color: colors.new.baseColor[400],
                fontFamily: 'Inter',
              }}
            />
          </Col>
        </FullWidthRow>

        <FullWidthRow>
          <Col span={24}>
            <Text>
              {data.percentage ? t('damPopup.percentage', { value: data.percentage }) : '-'}
            </Text>
          </Col>
        </FullWidthRow>
        {tag && (
          <FullWidthRow>
            <Col span={24}>
              <TagText text={getTagMessage(tag)} />
            </Col>
          </FullWidthRow>
        )}
      </Body>
    </Container>
  )
}
