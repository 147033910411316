import { Marker } from 'react-map-gl'
import { useTheme } from 'styled-components'

import {
  CameraIcon,
  ChlorophyllIcon,
  CloudyIcon,
  DropIcon,
  EmergenceIcon,
  GrainHumidityIndicatorIcon,
  HarvestedIcon,
  SatelliteIcon,
  WaterIcon,
  WeedsIcon,
} from 'assets/icons'
import { MapIndex } from 'types'

const sizeMap = {
  small: {
    drop: { width: 14, height: 19 },
    water: { width: 18, height: 12 },
    weeds: { width: 18, height: 15 },
    emergence: { width: 14, height: 25 },
    chlorophyll: { width: 14, height: 18 },
    harvested: { width: 18, height: 12 },
    grainHumidity: { width: 18, height: 18 },
    camera: { width: 18, height: 18 },
    cloudy: { width: 18, height: 18 },
    satellite: { width: 18, height: 18 },
  },
  medium: {
    drop: { width: 28, height: 38 },
    water: { width: 36, height: 24 },
    weeds: { width: 36, height: 30 },
    emergence: { width: 28, height: 50 },
    chlorophyll: { width: 28, height: 36 },
    harvested: { width: 36, height: 24 },
    grainHumidity: { width: 36, height: 36 },
    camera: { width: 36, height: 36 },
    cloudy: { width: 36, height: 36 },
    satellite: { width: 36, height: 36 },
  },
}

interface Props {
  latitude: number
  longitude: number
  unavailableImage: boolean
  isCloudy?: boolean
  isHarvested?: boolean
  unavailableIndex?: boolean
  size?: 'small' | 'medium'
  selectedMapIndex?: MapIndex
}

export const UnavailableIcon: React.FC<Props> = ({
  latitude,
  longitude,
  unavailableImage,
  size = 'small',
  isHarvested,
  isCloudy,
  unavailableIndex,
  selectedMapIndex,
}) => {
  const { colors } = useTheme()

  const conditions: Record<StatusKey, boolean> = {
    isHarvested: isHarvested ?? false,
    unavailableImage: unavailableImage ?? false,
    unavailableIndex: unavailableIndex ?? false,
    isCloudy: isCloudy ?? false,
  }
  const iconSize = sizeMap[size]
  const iconProps = { color: colors.black }

  const INDEX_ICON = {
    irrigation: <DropIcon {...iconProps} {...iconSize.drop} />,
    chlorophyll: <ChlorophyllIcon {...iconProps} {...iconSize.chlorophyll} />,
    realImage: <CameraIcon {...iconProps} {...iconSize.camera} />,
    grainHumidity: <GrainHumidityIndicatorIcon {...iconProps} {...iconSize.grainHumidity} />,
    water: <WaterIcon {...iconProps} {...iconSize.water} />,
    weeds: <WeedsIcon {...iconProps} {...iconSize.weeds} />,
    emergence: <EmergenceIcon {...iconProps} {...iconSize.emergence} />,
  }

  const STATUS_ICON = {
    isHarvested: <HarvestedIcon {...iconProps} {...iconSize.harvested} />,
    unavailableImage: <SatelliteIcon {...iconProps} {...iconSize.satellite} />,
    unavailableIndex: selectedMapIndex ? INDEX_ICON[selectedMapIndex] : null,
    isCloudy: <CloudyIcon {...iconProps} {...iconSize.cloudy} />,
  }
  type StatusKey = keyof typeof STATUS_ICON

  const status = (Object.keys(conditions) as StatusKey[]).find(key => conditions[key])

  if (!status) return null

  return (
    <Marker latitude={latitude} longitude={longitude} draggable={false}>
      {STATUS_ICON[status]}
    </Marker>
  )
}
